import React, {useState, useEffect} from 'react'
import {Router, Route, Switch, Redirect} from "react-router-dom"
import axios from './axios'
import history from './history'

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useAuthContext} from './auth'
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core'

import Layout from './components/layout'
import Login from './components/Login'
import MasterLogin from './components/MasterLogin'
import Signup from './components/Signup'
import Users from './components/users'
import Returns from './components/returns'
import Roles from './components/roles'
import Checkouts from './components/checkouts'
import Checkout from './components/checkouts/Checkout'
import OrderReports from './components/order-reports'
import ItemLogs from './components/item-logs'
import Diamonds from './components/diamonds'
import Feeds from './components/feeds'
import WebCustomers from './components/web-customers'
import WebCustomer from './components/web-customers/WebCustomer'
import CustomerOrders from './components/customer-orders'
import CustomerOrder from './components/customer-orders/CustomerOrder'
import EditOrder from './components/customer-orders/EditOrder'
import Orders from './components/orders'
import OrderedJewelryStones from './components/ordered-jewelry-stones'
import ListedDiamonds from './components/listed-diamonds'
import Vendors from './components/vendors'
import JewelryStoneSuppliers from './components/jewelry-stone-suppliers'
import Retailers from './components/retailers'
import Payments from './components/payments'
import Locations from './components/locations'
import Reps from './components/reps'
import Warranties from './components/warranties'
import Reports from './components/reports'
import Scrape from './components/scrape'
import Dashboard from './components/Dashboard'
import ProtectedRoute from './components/ProtectedRoute'
import Settings from './components/Settings'
import Account from './components/Account'
import Backlinks from './components/backlinks'
import LiveDashboard from './components/LiveDashboard'
// import Backlink from './components/backlinks/Backlink'
import PageNotFound from './components/PageNotFound'
import PageLoading from './components/PageLoading'
import { getReturnUrl, getRedirectUrl } from './utils'
import Holidays from './components/Holidays';
import Customers from './components/customers';
import ResetPassword from './components/users/ResetPassword';
import RequestPassword from './components/users/RequestPassword';
import Stores from './components/stores';
import StoreUsers from './components/stores/StoreUsers';
import Terms from './components/Terms';
import ReceiveDiamonds from './components/orders/ReceiveDiamonds';
import { Scanner } from './components/item-logs/Scanner';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4791db',
      main: '#1976d2',
      dark: '#115293',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    htmlFontSize: 10,
    h6: {
      fontSize: '1.6rem'
    },
    body1: {
      fontSize: '1.4rem',
    },
  }
})

function App() {
  const {login, isLoggedIn} = useAuthContext()
  const [loading, setLoading] = useState(true)

  const getLoggedInUser = async () => {
    try {
      //test mode
      // const user = JSON.parse(localStorage.getItem('user'))
      // const expiry = localStorage.getItem('expiry')
      // if (user && expiry)
      //   login({user, expiry})

      //production
      const { data } = await axios.get('/users/loggedin')
      login(data)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLoggedInUser()
  }, [])

  const LoggedOutRoutes = () => (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/password-reset/:slug">
        <ResetPassword />
      </Route>
      <Route path="/password-request">
        <RequestPassword />
      </Route>
      <Route path="/vendor/signup">
        <Signup />
      </Route>
      <Redirect 
        to={{
          pathname: '/login',
          search: getReturnUrl()
        }}
      />
    </Switch>
  )

  const LoggedInRoutes = () => (
    <Layout>
      <Switch>
        <ProtectedRoute path="/users" guard='readUser' component={Users} />
        <ProtectedRoute path="/roles" guard='readRole' component={Roles} />
        <ProtectedRoute path="/checkouts/:id" guard='readCheckout' component={Checkout} />
        <ProtectedRoute path="/checkouts" guard='readCheckout' component={Checkouts} />
        <ProtectedRoute path="/order-reports" guard='readOrderReport' component={OrderReports} />
        <ProtectedRoute path="/item-logs/scanner" guard='readOrderReport' component={Scanner} />
        <ProtectedRoute path="/item-logs" guard='readOrderReport' component={ItemLogs} />
        <ProtectedRoute path="/payments" guard='readPayment' component={Payments} />
        <ProtectedRoute path="/diamond-search" guard='readDiamond' component={Diamonds} />
        <ProtectedRoute path="/customer-orders/:id/edit" guard='writeCustomerOrder' component={EditOrder} />
        <ProtectedRoute exact path="/customer-orders/:id" guard='readCustomerOrder' component={CustomerOrder} />
        <ProtectedRoute exact path="/customer-orders" guard='readCustomerOrder' component={CustomerOrders} />
        <ProtectedRoute exact path="/customers" guard='readCustomerOrder' component={Customers} />
        <ProtectedRoute exact path="/stores/:id" guard='readStore' component={StoreUsers} />
        <ProtectedRoute exact path="/stores" guard='readStore' component={Stores} />
        <ProtectedRoute exact path="/listed-diamonds" guard='readOrder' component={ListedDiamonds} />
        <ProtectedRoute exact path="/orders/receive" guard='writeOrder' component={() => <ReceiveDiamonds receive />} />
        <ProtectedRoute exact path="/orders/invoice" guard='writeOrder' component={ReceiveDiamonds} />
        <ProtectedRoute path="/orders/:status" guard='readOrder' component={Orders} />
        <ProtectedRoute exact path="/orders" guard='readOrder' component={Orders} />
        <ProtectedRoute exact path="/ordered-jewelry-stones" guard='readOrder' component={OrderedJewelryStones} />
        <ProtectedRoute path="/vendors" guard='readSupplier' component={Vendors} />
        <ProtectedRoute path="/jewelry-stone-suppliers" guard='readSupplier' component={JewelryStoneSuppliers} />
        <ProtectedRoute path="/diamond-feed" guard='readFeed' component={Feeds} />
        <ProtectedRoute path="/retailers" guard='readRetailer' component={Retailers} />
        <ProtectedRoute path="/locations" guard='readLocation' component={Locations} />
        <ProtectedRoute path="/reps" guard='readLocation' component={Reps} />
        <ProtectedRoute path="/warranties" guard='readSupplier' component={Warranties} />
        <ProtectedRoute path="/reports" guard='readReport' component={Reports} />
        <ProtectedRoute path="/live-dashboard" guard='readReport' component={LiveDashboard} />
        <ProtectedRoute path="/returns" guard='readReturn' component={Returns} />
        <ProtectedRoute path="/scrape" guard='readScrape' component={Scrape} />
        <ProtectedRoute path="/customer-board/:id" guard='readWebCustomer' component={WebCustomer} />
        <ProtectedRoute path="/customer-board" guard='readWebCustomer' component={WebCustomers} />
        <ProtectedRoute path="/master-login" guard='readUser' component={MasterLogin} />
        {/* <ProtectedRoute path="/backlinks/:id" guard='readReport' component={Backlink} /> */}
        <ProtectedRoute path="/backlinks" guard='readBacklinks' component={Backlinks} />
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/account">
          <Account />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/holiday-info">
          <Holidays />
        </Route>
        <Route exact path="/terms-and-conditions">
          <Terms />
        </Route>
        <Redirect from="/login" to={getRedirectUrl()} />
        <Redirect exact from="/" to="/dashboard" />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Layout>
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer autoClose={1500} transition={Slide} hideProgressBar={true} />
      {loading ? 
        <PageLoading /> :
        <Router history={history}>
          {!isLoggedIn ? <LoggedOutRoutes /> : <LoggedInRoutes />}
        </Router>
      }
    </ThemeProvider>
  );
}

export default App;
