import React from 'react'
import * as Yup from 'yup'

import { Grid } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'

const JewelryStoneSupplierSchema = Yup.object().shape({
    name: Yup
        .string()
        .required('Name is required'),
    email: Yup.string().email('Invalid email'),
})

const JewelryStoneSupplierSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(4)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function JewelryStoneSupplier() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="email"
          label="Email"
        />
      </Grid>
    </Grid>
  )
}

const initialValues = {
    name: '',
    email: '',
}

export function JewelryStoneSupplierDialog(props) {
  return (
    <FormDialog 
      title="Jewelry Stone Supplier"
      routeOptions={{route: 'jewelry-stone-suppliers'}}
      validationSchema={JewelryStoneSupplierSchema}
      Skeleton={JewelryStoneSupplierSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <JewelryStoneSupplier />
    </FormDialog>
  )
}