import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { Link } from 'react-router-dom'
import { formatDate, formatCurrency } from '../../utils'
import { round } from 'lodash'
import { useAuthContext } from '../../auth'
import JobBags from './JobBags'

export default function Index() {
  useTitle('Orders')
  const {user} = useAuthContext()
  const {is_supplier, is_vs_retailer} = user

  const columns = React.useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'name',
        Cell: ({value, row}) => <Link to={`/customer-orders/${row.original._id}`}>{value}</Link>
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Customer',
        accessor: 'customer_name',
      },
      {
        Header: 'RMA',
        accessor: 'current_order_rma'
      },
      ...(is_supplier || is_vs_retailer) ? [] : [{
        Header: 'Margin',
        accessor: 'margin',
        Cell: ({value}) => `${round(value * 100, 1)}%`
      }],
      {
          Header: 'Revenue',
          accessor: 'total_item_price',
          Cell: ({value}) => formatCurrency(value),
          disableFilters: true
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({value}) => formatDate(value, 'l')
      },
    ], []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'customer-orders', selected: 'name external_id total_item_price current_order_rma email customer_name created_at cancelled_at margin', sorted: [{id: "created_at", desc: true}]}}
      header={<>
        Order
        <JobBags />
        </>}
      actions={{showActions: false}}
      getRowProps={(x, {row}) => {
        const isCancelled = !!row.original.cancelled_at
        const cancelledClass = isCancelled ? {className: 'cancelled'} : {}
        return {...x, ...cancelledClass}
    }}
    />
  )
}