import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { JewelryStoneSupplierDialog } from './JewelryStoneSupplier'
import useTitle from '../../utils/useTitle'
import { numEqFilter } from '../../utils'

export default function Index() {
  useTitle('Jewelry Stone Supplier')

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
        filter: numEqFilter
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'jewelry-stone-suppliers', selected: 'name email _id'}}
      header='Jewelry Stone Supplier'
      Dialog={JewelryStoneSupplierDialog}
      deleteTitle={<div>Are you sure you want to delete this supplier? <p style={{color: 'red', textTransform: 'uppercase'}}></p></div>}
    />
  )
}