import React from 'react';
import clsx from 'clsx';

import { NavLink } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { drawerWidth, DrawerStateContext } from '.';
import { Drawer, Hidden, Grid, Typography, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import  DashboardIcon from '@material-ui/icons/Dashboard';
import  GroupIcon from '@material-ui/icons/Group';
import  SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import  SettingsIcon from '@material-ui/icons/Settings';
import  AccountIcon from '@material-ui/icons/AccountCircle';
import  SearchIcon from '@material-ui/icons/Search';
import  ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import  ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import  ListAltIcon from '@material-ui/icons/ListAlt';
import  AssignmentIcon from '@material-ui/icons/Assignment';
import  AssessmentIcon from '@material-ui/icons/Assessment';
import  LogsIcon from '@material-ui/icons/History';
import RepIcon from '@material-ui/icons/SupervisorAccount';
import  AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import  LocationCityIcon from '@material-ui/icons/LocationCity';
import  StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import StoreIcon from '@material-ui/icons/Store';
import PaymentIcon from '@material-ui/icons/Payment';
import  RedeemIcon from '@material-ui/icons/Redeem';
import  ViewListIcon from '@material-ui/icons/ViewList';
import StorageIcon from '@material-ui/icons/Storage';
import LinkIcon from '@material-ui/icons/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useAuthContext } from '../../auth';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const DiamondIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 33 26">
    <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M8.798 0.316L22.383 0.316 25.375 1.937 30.485 6.922 15.528 23.748 0.447 6.922 6.181 1.812z" transform="translate(1 1)"/>
        <path d="M6.318 1.75L11.406 3.868 19.507 3.868 24.867 1.75" transform="translate(1 1)"/>
        <g>
            <path d="M0.064 3.701L8.278 5.82 21.355 5.82 29.852 3.577" transform="translate(1 1) translate(.633 3.345)"/>
            <path d="M10.796.598L8.244 5.758 14.788 20.216M18.904.598L21.456 5.758 14.912 20.216" transform="translate(1 1) translate(.633 3.345)"/>
        </g>
    </g>
</svg>

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerHeader: {
      backgroundColor: '#232f3e',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#1b2430',
      color: '#eee',
    },
  }),
);

const menu = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    to: '/dashboard',
  },
  {
    group: 'diamonds',
    items: [
      {
        text: 'Diamond Search',
        icon: <SearchIcon />,
        to: '/diamond-search',
        guard: 'readDiamond',
      },
      {
        text: 'Listed Diamonds',
        icon: <ViewListIcon />,
        to: '/listed-diamonds',
        guard: 'readOrder',
      },
    ]
  },
  {
    group: 'orders',
    items: [
      {
        text: 'Orders',
        icon: <AssignmentIcon />,
        to: '/customer-orders',
        guard: 'readCustomerOrder',
      },
      {
        text: 'Ordered Diamonds',
        icon: <DiamondIcon />,
        to: '/orders',
        guard: 'readOrder',
      },
      {
        text: 'Ordered Jewelry Stones',
        icon: <DiamondIcon />,
        to: '/ordered-jewelry-stones',
        guard: 'readOrder',
      },
      {
        text: 'Order Reports',
        icon: <AssignmentIcon />,
        to: '/order-reports',
        guard: 'readOrderReport',
      },
      {
        text: 'Item Logs',
        icon: <LogsIcon />,
        to: '/item-logs',
        guard: 'readOrderReport',
      },
      {
        text: 'Payments',
        icon: <PaymentIcon />,
        to: '/payments',
        guard: 'readPayment',
      },
      {
        text: 'Returns',
        icon: <AssignmentReturnIcon />,
        to: '/returns',
        guard: 'readReturn',
      },
    ]
  },
  {
    group: 'customers',
    items: [
      {
        text: 'Customers',
        icon: <ShoppingBasketIcon />,
        to: '/customers',
        guard: 'readCustomer',
      },
      {
        text: 'Customer Board',
        icon: <ListAltIcon />,
        to: '/customer-board',
        guard: 'readWebCustomer',
      },
      {
        text: 'Warranties',
        icon: <RedeemIcon />,
        to: '/warranties',
        guard: 'readWarranty',
      },
    ],
  },
  {
    group: 'vendors',
    items: [
      {
        text: 'Vendors',
        icon: <AssignmentIndIcon />,
        to: '/vendors',
        guard: 'readSupplier',
      },
      {
        text: 'Jewelry Stone Suppliers',
        icon: <AssignmentIndIcon />,
        to: '/jewelry-stone-suppliers',
        guard: 'readSupplier',
      },
      {
        text: 'Retailers',
        icon: <StoreIcon />,
        to: '/retailers',
        guard: 'readRetailer',
      },
      {
        text: 'Locations',
        icon: <LocationCityIcon />,
        to: '/locations',
        guard: 'readLocation',
      },
      {
        text: 'Reps',
        icon: <RepIcon />,
        to: '/reps',
        guard: 'readLocation',
      },
    ]
  },
  {
    text: 'Stores',
    icon: <StoreMallDirectoryIcon />,
    to: '/stores',
    guard: 'readStore',
  },
  {
    group: 'users',
    items: [
      {
        text: 'Users',
        icon: <GroupIcon />,
        to: '/users',
        guard: 'readUser',
      },
      {
        text: 'Roles',
        icon: <SupervisorAccountIcon />,
        to: '/roles',
        guard: 'readRole',
      },
    ]
  },
  {
    group: 'reports',
    items: [
      {
        text: 'Live Dashboard',
        icon: <ShowChartIcon />,
        to: '/live-dashboard',
        guard: 'readReport',
      },
      {
        text: 'Reports',
        icon: <AssessmentIcon />,
        to: '/reports',
        guard: 'readReport',
      },
      {
        text: 'Scrape',
        icon: <StorageIcon />,
        to: '/scrape',
        guard: 'readScrape',
      },
      {
        text: 'Backlinks',
        icon: <LinkIcon />,
        to: '/backlinks',
        guard: 'readBacklinks'
      },
      {
        text: 'Checkouts',
        icon: <ShoppingCartIcon />,
        to: '/checkouts',
        guard: 'readCheckout'
      },
    ]
  },
  {
    text: 'Account',
    icon: <AccountIcon />,
    to: '/account'
  },
  {
    text: 'Settings',
    icon: <SettingsIcon />,
    to: '/settings'
  },
];

const groups = {
  diamonds: {
    text: 'Diamonds',
    icon: <DiamondIcon />,
  },
  customers: {
    text: 'Customers',
    icon: <ShoppingBasketIcon />,
  },
  orders: {
    text: 'Orders',
    icon: <AssignmentIcon />,
  },
  users: {
    text: 'Users',
    icon: <GroupIcon />,
  },
  reports: {
    text: 'Reports',
    icon: <AssessmentIcon />,
  },
  vendors: {
    text: 'Vendors',
    icon: <AssignmentIndIcon />,
  }
}

const MenuItem = ({text, icon, to, onClick}) => {
  return <ListItem onClick={onClick} component={NavLink} to={to} button>
    <ListItemIcon style={{color: '#eee'}}>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
}

export default function AppDrawer() {
  const classes = useStyles()
  const drawerState = React.useContext(DrawerStateContext)
  const {hasPermission} = useAuthContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [expanded, setExpanded] = React.useState({})
  const userMenu = menu
    .map(x => {
      if (x.group) {
        const items = x.items.filter(({guard}) => !guard || hasPermission(guard))
        if (!items.length) {
          return null
        }
        else if (items.length === 1) {
          return items[0]
        } else {
          return {...x, items}
        }
      }
      return x
    })
    .filter(x => x)
    .filter(({guard}) => !guard || hasPermission(guard))

  const drawer = (
    <div>
      <div className={clsx(classes.toolbar, classes.drawerHeader)}>
        <Grid container alignItems="center" justify="center" className={classes.toolbar}>
          <Grid item>
            <Typography variant="h6">
              Ritani
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <List>
        {userMenu
        .map(({text, icon, to, group, items=[]}) => {
          if (!group) {
            return <MenuItem text={text} icon={icon} to={to} key={text} onClick={isMobile ? drawerState.handleDrawerToggle : null} />
          } else {
            return <>
              <ListItem button onClick={() => {
                setExpanded({...expanded, [group]: !expanded[group]})
              }} key={group}
              >
                <ListItemIcon style={{color: '#eee'}}>{groups[group].icon}</ListItemIcon>
                <ListItemText primary={groups[group].text} />
                <ListItemIcon style={{color: '#eee'}}>{expanded[group] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              </ListItem>
              <Collapse in={!!expanded[group]} timeout="auto">
                  <List style={{
                    paddingLeft: theme.spacing(4)
                  }}>{items
                    .map(({text, icon, to}) => (
                      <MenuItem text={text} icon={icon} to={to} key={text} onClick={isMobile ? drawerState.handleDrawerToggle : null} />
                    ))
                  }
                </List>
              </Collapse>
            </>
          }
        })}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="navigation">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={drawerState.mobileOpen}
            onClose={drawerState.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
  )
}
