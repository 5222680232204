import React from 'react'
import * as Yup from 'yup'
import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import Select from '../form/Select'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import AsyncAutoComplete from '../form/AutoComplete'

const stoneTypes = {
    SA: 'Sapphire',
    RU: 'Ruby',
    DB: 'Black Diamond',
    D: 'Diamond',
    LD: 'Lab Diamond'
}

const shapes = {
    RD: 'Round',
    TR: 'Trillion',
    BG: 'Baguette',
    PR: 'Princess',
    MQ: 'Marquise',
    TZ: 'Trapezoid',
    TB: 'Tapered Baguette',
}

const statuses = {
    pending: 'Pending',
    ordered: 'Ordered',
    received: 'Received',
    cancelled: 'Cancelled',
    stock: 'Stock'
}

const OrderedJewelryStoneSchema = Yup.object().shape({
    qty: Yup.number().min(1),
    order_name: Yup.string(),
    shape: Yup.string().oneOf(Object.keys(shapes)),
    stone_type: Yup.string().oneOf(Object.keys(stoneTypes)),
    length: Yup.number(),
    width: Yup.number(),
    carat: Yup.number(),
    jewelry_stone_supplier_id: Yup.object().shape({
        _id: Yup
          .string()
    }),
    fulfillment_status: Yup.string().oneOf(Object.keys(statuses)),
    sku: Yup.string(),
})

const OrderedJewelryStoneSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(4)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function OrderedJewelryStone() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="order_name"
          label="Order Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          name="shape"
          label="Shape"
          placeholder="Select Shape"
          options={Object.entries(shapes).map(([key, value]) => ({key, value}))}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          name="stone_type"
          label="Stone Type"
          placeholder="Select Stone Type"
          options={Object.entries(stoneTypes).map(([key, value]) => ({key, value}))}
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="length"
          label="Length"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="width"
          label="Width"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="carat"
          label="Carat"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="qty"
          label="Quantity"
          type="number"
        />
      </Grid>   
      <Grid item xs={6}>
          <AsyncAutoComplete
            name="jewelry_stone_supplier_id"
            errorPath="jewelry_stone_supplier_id._id"
            label="Supplier"
            routeOptions={{route: 'jewelry-stone-suppliers', selected: 'name', searchField: 'name'}}
            getOptionSelected={(option, value) => {
              return option._id === value._id
            }}
            getOptionLabel={option => option.name || ''}
          />
      </Grid>   
      <Grid item xs={6}>
        <Select
          name="fulfillment_status"
          label="Status"
          placeholder="Select Status"
          options={Object.entries(statuses).map(([key, value]) => ({key, value}))}
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="sku"
          label="SKU"
        />
      </Grid>
    </Grid>
  )
}

const initialValues = {
    order_name: '',
    shape: '',
    stone_type: '',
    length: '',
    width: '',
    carat: '',
    status: '',
    jewelry_stone_supplier_id: {
        _id: ''
    },
    fulfillment_status: '',
    sku: ''
}

export function OrderedJewelryStoneDialog(props) {
    return (
        <FormDialog 
            title="Jewelry Stone Supplier"
            routeOptions={{route: 'ordered-jewelry-stones', populated: 'jewelry_stone_supplier_id'}}
            validationSchema={OrderedJewelryStoneSchema}
            Skeleton={OrderedJewelryStoneSkeleton}
            defaultValues={initialValues}
            {...props}
            transformData={data => {
                data.jewelry_stone_supplier_id = data.jewelry_stone_supplier_id?._id || null
            }}
        >
        <OrderedJewelryStone />
        </FormDialog>
    )
}