import React, { useState } from 'react'
import DeliveryIcon from '@material-ui/icons/TrafficOutlined'
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'

export default function DownloadPickups() {
    const [loading, setLoading] = useState(false)

    const download = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/ordered-jewelry-stones/pickup`)
            window.open(data.url, '_blank')
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    return (
        <Tooltip title="Download Pickups">
            <IconButton onClick={download}>
                {loading ? <CircularProgress size={20} /> : <DeliveryIcon />}
            </IconButton>
        </Tooltip>
    );
}