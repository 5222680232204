import React from 'react'
import Button from '@material-ui/core/Button'
import BagIcon from '@material-ui/icons/ShoppingBasket'
import { Tooltip, IconButton, TextField } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'


export default function JobBags({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [endDate, setEndDate] = React.useState()
  const [startDate, setStartDate] = React.useState()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
        try {
            setLoading(true)
            
            const {data: {url}} = await axios.post(`/order-reports/print-job-bags`, {
                startDate, endDate
            }, {timeout: 30000})
            window.open(url, '_blank')
            handleClose()
            // fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Print job bags`}>
        <IconButton onClick={handleClickOpen}>
          <BagIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Print shipping label`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Print job bags
          </Button>
        </>}
      >
        Print job bags?<br /><br />
        <TextField 
            type='date'
            value={endDate}
            label="End Date"
            onChange={e => {
                setEndDate(e.target.value)
              }
            }
        /><br />
        <TextField
            type='date'
            value={startDate}
            label="Start Date"
            onChange={e => {
                setStartDate(e.target.value)
            }}
        />
      </BasicDialog>
    </>
  );
}