import React, { useState } from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import moment from 'moment'
import {OrderedJewelryStoneDialog} from './OrderedJewelryStones'
import DownloadPickups from './DownloadPickups'

const stoneTypes = {
    SA: 'Sapphire',
    RU: 'Ruby',
    DB: 'Black Diamond',
    D: 'Diamond',
    LD: 'Lab Diamond'
}

const shapes = {
    RD: 'Round',
    TR: 'Trillion',
    BG: 'Baguette',
    PR: 'Princess',
    MQ: 'Marquise',
    TZ: 'Trapezoid',
    TB: 'Tapered Baguette',
}

export default function Index() {
    // const [loading, setLoading] = useState()

    useTitle('Orders')

    // const downloadReport = async (selectedRows, date) => {
    //     try {
    //         setLoading(true)
    //         const {data} = await axios.post('/orders/orderreport', {ids: selectedRows.map(x => x._id), date})
    //         const blob = new Blob([data], { type: 'text/csv' })
    //         downloadBlob(blob, 'report.csv') 
    //     } catch(e) {
    //         handleErrors(e)
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const columns = React.useMemo(() => [
        {
            Header: 'Order',
            accessor: 'order_name',
        },
        {
            Header: 'Date',
            accessor: 'createdAt',
            Cell: ({value}) => moment(value).format('M/D/YYYY h:mm A'),
            disableFilters: true
        },
        {
            Header: 'Order Status',
            accessor: 'cancelled_at',
            Cell: ({value}) => value ? 'Cancelled' : 'Active',
        },
        {
            Header: 'Fulfillment Status',
            accessor: 'fulfillment_status',
        },
        {
            Header: 'Supplier',
            accessor: 'jewelry_stone_supplier_id.name',
            disableFilters: true
        },
        {
            Header: 'SKU',
            accessor: 'sku',
        },
        {
            Header: 'Quantity',
            accessor: 'qty',
        },
        {
            Header: 'Stone',
            accessor: 'shape',
            Cell: ({row}) => `${shapes[row.original.shape] || row.original.shape} ${row.original.length} x ${row.original.width}`,
            disableFilters: true
        },
        {
            Header: 'Stone Type',
            accessor: 'stone_type',
            Cell: ({value}) => stoneTypes[value] || value || '',
        }
    ], [])
    
    return (
        <>
            <DefaultTable 
                columns={columns} 
                routeOptions={{
                    route: 'ordered-jewelry-stones', 
                    selected: 'createdAt order_name jewelry_stone_supplier_id qty shape part model length width carat stone_type sku fulfillment_status cancelled_at',
                    populated: {path: 'jewelry_stone_supplier_id', select: '-_id name'},
                }}
                header={<>
                    Ordered Jewelry Stones
                    <DownloadPickups />
                </>}
                actions={{canAdd: true, canEdit: true, canDelete: false}}
                Dialog={OrderedJewelryStoneDialog}
                // selectedActions={[
                //     ({selectedRows}) => <div style={{display: 'flex'}}>
                //         <DelayedEmail selectedRows={selectedRows} />
                //         <Button loading={loading} onClick={() => downloadReport(selectedRows)}>Download</Button>
                //     </div>
                // ]}
            />
        </>
    )
}