import React from 'react'
import Button from '@material-ui/core/Button'
import BuildIcon from '@material-ui/icons/Build'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import { Tooltip, IconButton, MenuItem, Grid } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Input from '../form/Input'
import Select from '../form/Select'
import states from '../../utils/states.json'
import countries from '../../utils/countries.json'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'
import Checkbox from '../form/Checkbox'
import { useHistory } from 'react-router-dom'

const addressSchema = Yup.object().shape({
    first_name: Yup
    .string()
        .required('First name is required'),
    last_name: Yup
        .string()
            .required('Last name is required'),
    address1: Yup.string()
        .required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state_code: Yup.string().required('State is required'),
    country_code: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zipcode is required'),
    phone: Yup.string(),
})

const orderSchema = Yup.object().shape({
    name: Yup.string()
        .required('Order name is required'),
    email: Yup.string()
        .email()
        .required('Email is required'),
    phone: Yup.string().nullable(),
    shipping_address: addressSchema,
    items: Yup
        .array()
        .of(Yup.object().shape({
            sku: Yup.string(),
            selected: Yup.boolean(),
        }))
        .test('item', 'One or more items must be selected', val => val.some(x => x.selected)),
    notes: Yup.string().required('Notes is required'),
    reason: Yup.string().oneOf(['repair', 'return', 'maintenance', 'resizing', 'remake']),
    create_label: Yup.boolean(),
    current_finger_size: Yup.string().when('reason', (reason, schema) => reason === 'resizing' ? schema.required('Finger size is required') : schema),
    new_finger_size: Yup.string().when('reason', (reason, schema) => reason === 'resizing' ? schema.required('Finger size is required') : schema),
    // billing_address: addressSchema,
})

export default function CreateRepair(data) {
    const name = data.name
    const isReturn = !!data.isReturn 
    const title = isReturn ? 'Return' : 'Repair'
    const [open, setOpen] = React.useState(false)
    const history = useHistory()
    const prefix = isReturn ? 'RT' : 'SREP'

    const methods = useForm({ 
        defaultValues: {
            ...data, old_name: data.name, name: prefix+'-'+data.name.replace(/#/g, ''), 
            reason: isReturn ? 'return' : '', 
            items: data.line_items.map(x => ({sku: x.sku, selected: true})),
            create_label: true
        }, resolver: yupResolver(orderSchema),
    })

    const items = methods.watch('items')
    const itemError = methods.formState.errors.items?.message

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async (data) => {
        try {
            if (data.reason === 'resizing') {
                data.notes += `\nFinger Size: ${data.current_finger_size} -> ${data.new_finger_size}`
            }
            const {data: {order}} = await axios.post(`/customer-orders/repairs`, data)
            toast.success('Successfully created')
            setOpen(false)
            if (order._id)
                history.push(`/customer-orders/${order._id}`)
        } catch(err) {
            handleErrors({err})
        }
    }

    const reason = methods.watch('reason')

    return (
        <>
        <Tooltip title={`Create ${title}`}>
            <IconButton onClick={handleClickOpen}>
            {isReturn ? <AssignmentReturnIcon /> : <BuildIcon />}
            </IconButton>
        </Tooltip>
        <BasicDialog
            open={open}
            onClose={handleClose}
            title={`Create ${title} for ${name}`}
        >
            <Form
            onSubmit={handleSubmit}
            methods={methods}
            >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                <Checkbox
                    name="create_label"
                    label="Create Label"
                />
                </Grid>
                <Grid item xs={6}>
                <Input
                    name="name"
                    label="Order Name"
                />
                </Grid>
                <Grid item xs={6}>
                {!isReturn && <Select
                    name="reason"
                    label="Reason"
                    placeholder="Reason"
                >
                    <MenuItem key='repair' value='repair'>Repair</MenuItem>
                    {/* <MenuItem key='return' value='return'>Return</MenuItem> */}
                    <MenuItem key='maintenance' value='maintenance'>Maintenance</MenuItem>
                    <MenuItem key='resizing' value='resizing'>Resizing</MenuItem>
                    <MenuItem key='remake' value='remake'>Remake</MenuItem>
                </Select>}
                </Grid>
                <Grid item xs={6}>
                <Input
                    name="email"
                    label="Email"
                />
                </Grid>
                <Grid item xs={6}>
                <Input
                    name="phone"
                    label="Phone"
                />
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{marginBottom: '0'}}>Selected Items</h3>
                    {items.map((x, idx) => <div key={idx}>
                        SKU: {x.sku}
                        <Checkbox style={{marginLeft: '10px'}} name={`items[${idx}].selected`} />
                    </div>)}
                    {itemError && <div style={{color: 'red'}}>{itemError}</div>}
                </Grid>
                {reason === 'resizing' ? <><Grid item xs={6}>
                    <Input
                        name="current_finger_size"
                        label="Current Finger Size"
                        type="number"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="new_finger_size"
                        label="New Finger Size"
                        type="number"
                    />
                </Grid></> : null}
                <Grid item xs={12}>
                    <Input
                        multiline
                        style={{width: '100%'}}
                        rows={4}
                        name="notes"
                        label="Notes"
                        placeholder={
                            reason === 'remake' ? 'please explain what is wrong with the ring why remake was approved. This will help the team understand if there is a structural issue with the ring' 
                            : reason === 'repair' ? 'please explain what the issue is that needs to be addressed' 
                            : 'Notes'
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                <h3>Shipping Address</h3>
                </Grid>
                <Grid item xs={6}>
                <Input
                    name="shipping_address.first_name"
                    label="First name"
                />
                </Grid>
                <Grid item xs={6}>
                <Input
                    name="shipping_address.last_name"
                    label="Last name"
                />
                </Grid>
                <Grid item xs={6}>
                <Input 
                    name="shipping_address.address1"
                    label="Address 1"
                />
                </Grid>
                <Grid item xs={6}>
                <Input 
                    name="shipping_address.address2"
                    label="Address 2"
                />
                </Grid>
                <Grid item xs={6}>
                <Input 
                    name="shipping_address.city"
                    label="City"
                />
                </Grid>
                <Grid item xs={6}>
                <Select 
                    name="shipping_address.state_code"
                    label="State"
                    placeholder='State'
                >
                    {states.map(x => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem>)}
                </Select>
                </Grid>
                <Grid item xs={6}>
                <Select 
                    name="shipping_address.country_code"
                    label="Country"
                >
                    {countries.map(x => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem>)}
                </Select>
                </Grid>
                <Grid item xs={6}>
                <Input 
                    name="shipping_address.zip"
                    label="Zip"
                />
                </Grid>
                <Grid item xs={6}>
                <Input 
                    name="shipping_address.phone"
                    label="Phone"
                />
                </Grid>
            </Grid>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
            </Button>
            <Button loading={methods.formState.isSubmitting} disabled={methods.formState.isSubmitting} type="submit" color="secondary">
                Create {title}
            </Button>
            </Form>
        </BasicDialog>
        </>
    );
}